async function drawBracket(bracket, speed, nextRound, forceRound, nextStep, forceStep, killGen) {
    const canvas = document.getElementById("bracket-canvas");
    canvas.style.display = "inherit";
    canvas.width = Math.max(window.innerWidth, 1300);
    canvas.height = Math.max(window.innerHeight * .95, 700);

    nextStep.value = false;
    forceStep.value = false;
    nextRound.value = false;
    forceRound.value = false;

    if (canvas.getContext) {
      const ctx = canvas.getContext("2d");

      var w = Math.max(document.documentElement.clientWidth, canvas.width);
      var h = Math.max(document.documentElement.clientHeight, canvas.height) * .95;
      var hOrig = h / 32;
      var wUnit = w / 12;
      for (let j = 0; j < 6; j++) {
        var hUnit = hOrig * (2 ** j);
        for (let i = 0; i < (32 / (2 ** j)); i++) {
          var x = wUnit * j;
          var y = (hUnit * i) + (hUnit / 2);
          if (j === 5) {
            ctx.fillRect(x, y - (hUnit / 8), wUnit, 1);
            ctx.fillRect(w - x, y + (hUnit / 8), -wUnit, 1);
          } else {
            ctx.fillRect(x, y, wUnit, 1);
            ctx.fillRect(w - x, y, -wUnit, 1);
          }

          if (speed === "step" || speed === "slow" || speed === "normal") {
            await new Promise(r => setTimeout(r, 25 * (j + 1)));
          } else if (speed === "fast") {
            await new Promise(r => setTimeout(r, 15 * (j + 1)));
          } else if (speed === "faster") {
            await new Promise(r => setTimeout(r, 5 * (j + 1)));
          }

          if (i % 2 === 1) {
            ctx.fillRect(x + wUnit, y, 1, -hUnit);
            ctx.fillRect(w - x - wUnit, y, -1, -hUnit);
          }

          if (speed === "step" || speed === "slow" || speed === "normal") {
            await new Promise(r => setTimeout(r, 25 * (j + 1)));
          } else if (speed === "fast") {
            await new Promise(r => setTimeout(r, 15 * (j + 1)));
          } else if (speed === "faster") {
            await new Promise(r => setTimeout(r, 5 * (j + 1)));
          }
        }
      }

      if (killGen.value) {
        return;
      }

      const teamsCanvas = document.getElementById("team-canvas");
      teamsCanvas.style.display = "inherit";
      teamsCanvas.width = canvas.width;
      teamsCanvas.height = canvas.height;

      if (killGen.value) {
        teamsCanvas.style.display = "none";
        return;
      }

      if (teamsCanvas.getContext) {
        const teamCtx = teamsCanvas.getContext("2d");

        let teamSprites = [];
        for (let j = 0 ; j < 2; j++) {
          for (let i = 0; i < 32; i++) {
            if (j === 0) {
              teamSprites.push({id: j * 32 + i, wins: bracket.filter(x => x === bracket[j * 32 + i]).length, name: bracket[j * 32 + i], direction: 1, currentX: 0, currentY: 0, targetX: 0, targetY: hOrig * (i + .075), width: wUnit * .85, height: hOrig * .85});
            } else {
              teamSprites.push({id: j * 32 + i, wins: bracket.filter(x => x === bracket[j * 32 + i]).length, name: bracket[j * 32 + i], direction: -1, currentX: teamsCanvas.width, currentY: 0, targetX: teamsCanvas.width, targetY: hOrig * (i + .075), width: -wUnit * .85, height: hOrig * .85});
            }
          }
        }

        let prevAliveIds = [];
        let aliveIds = [];
        for (let k = 0; k < 64; k++) {
          aliveIds.push(k);
        }
        if (speed === "step" || speed === "slow") {
          for (let k = 0; k < 7; k++) {
            if (k !== 0 && k !== 6) {
              prevAliveIds = aliveIds;
              aliveIds = [];
              for (let i = 0; i < (32 / (2 ** (k - 1))); i++) {
                let team1 = teamSprites[prevAliveIds[i * 2]];
                let team2 = teamSprites[prevAliveIds[i * 2 + 1]];
                let winner = team1.wins > team2.wins ? team1 : team2;
                let blockSize = 2 ** k;
                winner.targetX += winner.direction * wUnit;
                if (winner.id % blockSize < blockSize / 2) {
                  if (k === 5) {
                    if (winner.direction === 1){
                      winner.targetY += (2 ** (k - 1)) * (hOrig / 2) * 0.5;
                    } else {
                      winner.targetY += (2 ** (k - 1)) * (hOrig / 2) * 1.5;
                    }
                  } else {
                    winner.targetY += (2 ** (k - 1)) * (hOrig / 2);
                  }
                } else {
                  if (k === 5) {
                    if (winner.direction === 1){
                      winner.targetY -= (2 ** (k - 1)) * (hOrig / 2) * 1.5;
                    } else {
                      winner.targetY -= (2 ** (k - 1)) * (hOrig / 2) * 0.5;
                    }
                  } else {
                    winner.targetY -= (2 ** (k - 1)) * (hOrig / 2);
                  }
                }
                aliveIds.push(winner.id);
              }
            } else {
              if (k === 6) {
                let sprite = teamSprites[aliveIds[0]];
                if (sprite.direction === 1) {
                  sprite.targetY += 4 * hOrig;
                  sprite.targetX += wUnit - sprite.width / 2;
                } else {
                  sprite.targetY -= 4 * hOrig;
                  sprite.targetX -= wUnit - sprite.width / 2;
                }
              }
            }
            
            if (k !== 0) {
              for (let m = 0; m < aliveIds.length; m++) {
                if (speed === "slow") {
                  await new Promise(r => setTimeout(r, 1000));
                } else {
                  while (!nextRound.value && !nextStep.value) {
                    await new Promise(r => setTimeout(r, 10));
                  }
                }
                let movingTeam = teamSprites[aliveIds[m]];
                let moving = true;
                while (moving) {
                  if (speed === "slow" || (!forceRound.value && !forceStep.value)) {
                    await new Promise(r => setTimeout(r, 5));
                  }
                  moving = false;
                  let xEnabled = false;
                  teamCtx.clearRect(0, 0, teamsCanvas.width, teamsCanvas.height);
                  for (let i = 0; i < teamSprites.length; i++) {
                    let currentSprite = teamSprites[i];
                    if (currentSprite === movingTeam) {
                      if (Math.floor(currentSprite.currentX) !== Math.floor(currentSprite.targetX)) {
                        currentSprite.currentX += currentSprite.currentX > currentSprite.targetX ? -1 : 1;
                        moving = true;
                        xEnabled = true;
                      } else {
                        currentSprite.currentX = currentSprite.targetX;
                      }
                      if (!xEnabled) { 
                        if (Math.floor(currentSprite.currentY) !== Math.floor(currentSprite.targetY)) {
                          currentSprite.currentY += currentSprite.currentY > currentSprite.targetY ? -1 : 1;
                          moving = true;
                        } else {
                          currentSprite.currentY = currentSprite.targetY;
                        }
                      }
                    }
                    teamCtx.fillStyle = "#33ab85";
                    teamCtx.fillRect(currentSprite.currentX, currentSprite.currentY, currentSprite.width, currentSprite.height);
                    teamCtx.fillStyle = "black";
                    teamCtx.fillRect(currentSprite.currentX + currentSprite.direction, currentSprite.currentY + 1, currentSprite.width - (currentSprite.direction * 2), currentSprite.height - 2);
                    teamCtx.fillStyle = "#33ab85";
                    teamCtx.font = "15px serif";
                    let metrics = teamCtx.measureText(currentSprite.name);
                    let textWidth = metrics.width;
                    let textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
                    teamCtx.fillText(currentSprite.name, currentSprite.currentX + (currentSprite.width / 2) - (textWidth / 2), currentSprite.currentY + 1 + (currentSprite.height / 2) + (textHeight / 2));
                  }
                }
                nextStep.value = false;
                forceStep.value = false;
              }
            } else {
              let moving = true;
              while (moving) {
                if (speed === "slow" || (!forceRound.value && !forceStep.value)) {
                  await new Promise(r => setTimeout(r, 5));
                }
                moving = false;
                teamCtx.clearRect(0, 0, teamsCanvas.width, teamsCanvas.height);
                for (var i = 0; i < teamSprites.length; i++) {
                  let currentSprite = teamSprites[i];
                  if (Math.floor(currentSprite.currentX) !== Math.floor(currentSprite.targetX)) {
                    currentSprite.currentX += currentSprite.currentX > currentSprite.targetX ? -1 : 1;
                    moving = true;
                  } else {
                    currentSprite.currentX = currentSprite.targetX;
                  }
                  if (Math.floor(currentSprite.currentY) !== Math.floor(currentSprite.targetY)) {
                    currentSprite.currentY += currentSprite.currentY > currentSprite.targetY ? -1 : 1;
                    moving = true;
                  } else {
                    currentSprite.currentY = currentSprite.targetY;
                  }
                  teamCtx.fillStyle = "#33ab85";
                  teamCtx.fillRect(currentSprite.currentX, currentSprite.currentY, currentSprite.width, currentSprite.height);
                  teamCtx.fillStyle = "black";
                  teamCtx.fillRect(currentSprite.currentX + currentSprite.direction, currentSprite.currentY + 1, currentSprite.width - (currentSprite.direction * 2), currentSprite.height - 2);
                  teamCtx.fillStyle = "#33ab85";
                  teamCtx.font = "15px serif";
                  let metrics = teamCtx.measureText(currentSprite.name);
                  let textWidth = metrics.width;
                  let textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
                  teamCtx.fillText(currentSprite.name, currentSprite.currentX + (currentSprite.width / 2) - (textWidth / 2), currentSprite.currentY + 1 + (currentSprite.height / 2) + (textHeight / 2));
                }
              }
              nextStep.value = false;
              forceStep.value = false;
            }
            nextRound.value = false;
            forceRound.value = false;
          }
        } else {
          for (let k = 0; k < 13; k++) {
            if (k % 2 === 1) {
              if (speed === "normal") {
                await new Promise(r => setTimeout(r, 1000));
              } else if (speed === "fast") {
                await new Promise(r => setTimeout(r, 500));
              }
            }
  
            if (k !== 0 && k !== 12) {
              if (k % 2 === 1) {
                prevAliveIds = aliveIds;
                aliveIds = [];
                for (let i = 0; i < (32 / (2 ** Math.floor(k / 2))); i++) {
                  let team1 = teamSprites[prevAliveIds[i * 2]];
                  let team2 = teamSprites[prevAliveIds[i * 2 + 1]];
                  let winner = team1.wins > team2.wins ? team1 : team2;
                  winner.targetX += winner.direction * wUnit;
                  if (k === 11) {
                    winner.targetX -= winner.width / 2;
                  }
                  aliveIds.push(winner.id);
                }
              }
    
              if (k % 2 === 0) {
                for (let i = 0; i < (32 / (2 ** ((k - 2) / 2))); i++) {
                  let sprite = teamSprites[aliveIds[i]];
                  let blockSize = 64 / (32 / (2 ** ((k - 2) / 2)));
                  if (sprite.id % blockSize < blockSize / 2) {
                    if (k === 10) {
                      if (sprite.direction === 1){
                        sprite.targetY += (2 ** ((k / 2) - 1)) * (hOrig / 2) * 0.5;
                      } else {
                        sprite.targetY += (2 ** ((k / 2) - 1)) * (hOrig / 2) * 1.5;
                      }
                    } else {
                      sprite.targetY += (2 ** ((k / 2) - 1)) * (hOrig / 2);
                    }
                  } else {
                    if (k === 10) {
                      if (sprite.direction === 1){
                        sprite.targetY -= (2 ** ((k / 2) - 1)) * (hOrig / 2) * 1.5;
                      } else {
                        sprite.targetY -= (2 ** ((k / 2) - 1)) * (hOrig / 2) * 0.5;
                      }
                    } else {
                      sprite.targetY -= (2 ** ((k / 2) - 1)) * (hOrig / 2);
                    }
                  }
                }
              }
            } else {
              if (k === 12) {
                let sprite = teamSprites[aliveIds[0]];
                if (sprite.direction === 1) {
                  sprite.targetY += 4 * hOrig;
                } else {
                  sprite.targetY -= 4 * hOrig;
                }
              }
            }
            
            let moving = true;
            while (moving) {
              if (speed === "normal") {
                await new Promise(r => setTimeout(r, 5));
              } else if (speed === "fast") {
                await new Promise(r => setTimeout(r, 2));
              } else if (speed === "faster") {
                await new Promise(r => setTimeout(r, 1));
              }
              moving = false;
              teamCtx.clearRect(0, 0, teamsCanvas.width, teamsCanvas.height);
              for (let i = 0; i < teamSprites.length; i++) {
                let currentSprite = teamSprites[i];
                if (Math.floor(currentSprite.currentX) !== Math.floor(currentSprite.targetX)) {
                  currentSprite.currentX += currentSprite.currentX > currentSprite.targetX ? -1 : 1;
                  moving = true;
                } else {
                  currentSprite.currentX = currentSprite.targetX;
                }
                if (Math.floor(currentSprite.currentY) !== Math.floor(currentSprite.targetY)) {
                  currentSprite.currentY += currentSprite.currentY > currentSprite.targetY ? -1 : 1;
                  moving = true;
                } else {
                  currentSprite.currentY = currentSprite.targetY;
                }
                teamCtx.fillStyle = "#33ab85";
                teamCtx.fillRect(currentSprite.currentX, currentSprite.currentY, currentSprite.width, currentSprite.height);
                teamCtx.fillStyle = "black";
                teamCtx.fillRect(currentSprite.currentX + currentSprite.direction, currentSprite.currentY + 1, currentSprite.width - (currentSprite.direction * 2), currentSprite.height - 2);
                teamCtx.fillStyle = "#33ab85";
                teamCtx.font = "15px serif";
                let metrics = teamCtx.measureText(currentSprite.name);
                let textWidth = metrics.width;
                let textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
                teamCtx.fillText(currentSprite.name, currentSprite.currentX + (currentSprite.width / 2) - (textWidth / 2), currentSprite.currentY + 1 + (currentSprite.height / 2) + (textHeight / 2));
              }
            }
          }
        }
      }
    }
}

export default drawBracket;