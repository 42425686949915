import React, { useState, useEffect } from 'react';

function RotatingDigit({number, delay}) {
    const [rotatingDigit, setRotatingDigit] = useState(0);

    useEffect(() => {
        let nextDigit = 0;

        const updateRotatingDigit = () => {
            nextDigit = (nextDigit + 1) % 10;
            setRotatingDigit(nextDigit);
        };
    
        for (let x = 0; x < delay; x += 10) {
            setTimeout(updateRotatingDigit, x);
        }
    
        nextDigit = number;
        let timeCount = delay;
    
        for (let x = 0; x < 10; x++) {
            timeCount += x * Math.floor(delay / 100);
            setTimeout(updateRotatingDigit, timeCount);
        }

    }, [number, delay]);

    return (
        <p className="p-number">{rotatingDigit}</p>
    )
}

export default RotatingDigit;