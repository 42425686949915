import './App.css';
import NumberScroll from "./NumberScroll"
import drawBracket from "./BracketAnimation"
import $ from "jquery"
import React, { useState } from 'react';

function App() {
  var killGen = {value: false};
  function hideBracket() {
    killGen.value = true;

    document.getElementById("back-button-container").style.display = "none";

    const canvas = document.getElementById("bracket-canvas");
    if (canvas.getContext) {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    const teamsCanvasParent = document.getElementById("header");
    const teamsCanvasChild = document.getElementById("team-canvas");
    teamsCanvasParent.removeChild(teamsCanvasChild);
    var newTeamsCanvasChild = document.createElement('canvas');
    newTeamsCanvasChild.id = "team-canvas";
    teamsCanvasParent.appendChild(newTeamsCanvasChild);

    canvas.style.display = "none";
    newTeamsCanvasChild.style.display= "none";
    canvas.classList.remove("transition-grow-in");
  }

  function hideHome() {
    document.getElementById("header-content-box").style.display = "none";
    document.getElementById("generate-button-container").style.display = "none";
  }

  function showBracket() {
    document.getElementById("back-button-container").style.display = "flex";
    nextRound.value = true;
    forceRound.value = false;
    nextStep.value = true;
    forceStep.value = false;
  }

  function showHome() {
    document.getElementById("header-content-box").style.display = "";
    document.getElementById("generate-button-container").style.display = "";
  }

  var nextRound = {value: true};
  var forceRound = {value: false};
  var nextStep = {value: true};
  var forceStep = {value: false};
  
  const [speed, setSpeed] = useState("normal");
  var speedButton = null;
  function changeSpeed(newSpeed) {
    document.getElementById(speed).classList.remove("selectedButton");

    setSpeed(newSpeed);
    
    speedButton = document.getElementById(newSpeed);
    speedButton.classList.add("selectedButton");
  }

  function animateBracket() {
    $.get("https://bracketgenapi.azurewebsites.net/bracketGen", function(res) {
      console.log(res);
      var bracket = res.split(":");
      drawBracket(bracket, speed, nextRound, forceRound, nextStep, forceStep, killGen);
    });
  }

  function doNextRound() {
    if (nextRound.value) {
      forceRound.value = true;
    }
    nextRound.value = true;
  }

  function doNextMove() {
    if (nextStep.value) {
      forceStep.value = true;
    }
    nextStep.value = true;
  }

  var homeCurrent = true;
  function animateHomeToggle() {
    if (homeCurrent) {
      document.getElementById("header-content-box").classList.add("transition-grow-up");
      document.getElementById("generate-button-container").classList.add("transition-grow-down");

      setTimeout(() => {
        hideHome();
        animateBracket();
        showBracket();
        setTimeout(() => {
          document.getElementById("back-button-container").classList.remove("transition-grow-in");
        }, 10);
      }, 2000);

      homeCurrent = false;
    } else {
      document.getElementById("back-button-container").classList.add("transition-grow-in");
      document.getElementById("bracket-canvas").classList.add("transition-grow-in");
      document.getElementById("team-canvas").classList.add("transition-grow-in");

      updateGenCount();

      setTimeout(() => {
        hideBracket();
        showHome();
        setTimeout(() => {
          document.getElementById("header-content-box").classList.remove("transition-grow-up");
          document.getElementById("generate-button-container").classList.remove("transition-grow-down");
        }, 10);
      }, 2000);

      homeCurrent = true;
    }
  }

  const [genNum, setGenNum] = useState(null);
  $.get("https://bracket-count.azurewebsites.net/api/num_brackets", function(res) {
    setGenNum(parseInt(res));
  });

  async function updateGenCount() {
    $.get("https://bracket-count.azurewebsites.net/api/add_one", function(res) {
    });
    $.get("https://bracket-count.azurewebsites.net/api/num_brackets", function(res) {
      setGenNum(parseInt(res));
    });
  }

  return (
    <div className="App">
      <header id="header" className="App-header">
        <div id="header-content-box" className="header-content-box">
          <h1>Brackets Generated:</h1>
          {genNum && <NumberScroll number={genNum}></NumberScroll>}
        </div>
        <div id="generate-button-container" className="generate-button-container">
          <div className='speed-buttons'>
            <button id='step' className='speed-button' onClick={() => changeSpeed("step")}>Step</button>
            <button id='slow' className='speed-button' onClick={() => changeSpeed("slow")}>Slow</button>
            <button id='normal' className='speed-button selectedButton' onClick={() => changeSpeed("normal")}>Normal</button>
            <button id='fast' className='speed-button' onClick={() => changeSpeed("fast")}>Fast</button>
            <button id='instant' className='speed-button' onClick={() => changeSpeed("instant")}>Instant</button>
          </div>
          <button onClick={animateHomeToggle} className="generate-button"><span className="button-text">Generate</span></button>
        </div>

        <div id="back-button-container" className="back-button-container transition-grow-in">
          <button onClick={animateHomeToggle}>Back!</button>
          {speed === "step" && <button onClick={doNextRound}>Next Round</button>}
          {speed === "step" && <button onClick={doNextMove}>Next Move</button>}
        </div>
        <canvas id="bracket-canvas"></canvas>
        <canvas id="team-canvas"></canvas>
      </header>
      <footer>
        <h2 className="myName">Created by Logan Holmes</h2>
        <h2><a target="_blank" rel="noreferrer" href="https://www.loganbholmes.com"><i className="fa-solid fa-globe"></i></a> <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/realloganholmes/"><i className="fa-brands fa-linkedin"></i></a></h2>
      </footer>
    </div>
  );
}

export default App;