import React from 'react';
import RotatingDigit from './RotatingDigit';

function NumberScroll(number) {
    var strDigits = number.number.toString().split('');
    while (strDigits.length < 8) {
        strDigits.unshift(0);
    }
    var digits = strDigits.map(Number);

    return (
        <div className="generated-number">
            <div className="number-box" id="nb-tm"><RotatingDigit number={digits[0]} delay={500} /></div>
            <div className="number-box" id="nb-m"><RotatingDigit number={digits[1]} delay={700} /></div>
            <div className="number-box" id="nb-hth"><RotatingDigit number={digits[2]} delay={1000} /></div>
            <div className="number-box" id="nb-tth"><RotatingDigit number={digits[3]} delay={1400} /></div>
            <div className="number-box" id="nb-th"><RotatingDigit number={digits[4]} delay={1900} /></div>
            <div className="number-box" id="nb-h"><RotatingDigit number={digits[5]} delay={2500} /></div>
            <div className="number-box" id="nb-t"><RotatingDigit number={digits[6]} delay={3200} /></div>
            <div className="number-box" id="nb-o"><RotatingDigit number={digits[7]} delay={4000} /></div>
        </div>
    );
}

export default NumberScroll;